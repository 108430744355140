import React, { useCallback, useState } from 'react';
import EditIcon from '../../../components/Icons/EditIcon';
import StatusChip from '../../../components/StatusChip';
import { Link } from 'react-router-dom';
import PhoneNumbersList from '../../../components/PhoneNumbersList';
import { titleCaseFormatter } from '../../../utils/stringFormatters';
import ReactivateModal from '../../../components/ReactivateModal';
import ModalWithHeadingSmall from '../../../components/ModalWithHeadingSmall';
import { useDispatch, useSelector } from 'react-redux';
import ClientButton from '../../../components/ClientButton';
import { isEmpty } from 'lodash';
import { deactivateAcccountApi } from '../../../redux/Reducers/commonApis';
import Loader from '../../../components/Loader';
import toast from 'react-hot-toast';
import { getProfileDataApi } from '../../../redux/Reducers/ProfileReducer';
import { dateFn } from '../../../utils/dateTimeFormatter';
import { warningMsgTextRemoveFn, warningMsgTextRemoveFn2 } from '../../../utils/commonUtil';

export default function MyUser({ edit, setEdit, data }) {
  // console.log('edit :>> ', edit);
  const dispatch = useDispatch();
  const { profileData } = useSelector((state) => state.profile);
  const [activateModal, setActivateModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaderMsg, setLoaderMsg] = useState('processing...');
  const [accountData, setAccountData] = useState({});
  const deactivateModalFn = (e, val) => {
    e.stopPropagation();
    setDeactivateModal(val);
  };
  const showReactivateModalFn = useCallback((e, val) => {
    e.stopPropagation();
    setActivateModal(val);
  }, []);
  const deactivateFn = (payload) => {
    setLoading((prev) => !prev);
    const create = deactivateAcccountApi(payload);
    create?.then((res) => {
      if (res?.status === 400) {
        setLoading(false);
        setAccountData(res?.data);
      } else if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        toast.success(res?.message || 'Account deactivated successfully');
        setLoaderMsg('loading profile data...');
        dispatch(getProfileDataApi(true));
        setTimeout(() => {
          setDeactivateModal(false);
        }, 1000);
      } else {
        setLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  const onYesDeactivateFn = () => {
    const payload = {
      warning_confirmation: true,
    };
    !isEmpty(accountData) ? deactivateFn(payload) : deactivateFn({});
  };
  return (
    <div className=''>
      <div className='z-0 rounded-lg bg-white '>
        <header className='flex items-center border-b border-gray-100 p-6'>
          <h5 className='go-textnormal-18 my-1 flex-1'>My User</h5>
          <div className='flex'>
            <button className='go-btn-primary mr-2'>
              <EditIcon
                onClick={() => {
                  setEdit(!edit);
                }}
              />
            </button>
          </div>
        </header>
        <div className='p-6'>
          <div className='mb-4 flex-row space-y-1.5'>
            <div className='go-label-16'>Name</div>
            {data?.account_type === 'ORGANIZATION' ? (
              <div className='go-textblacknormal-16 font-medium'>
                {data?.org_admin_first_name + ' ' + data?.org_admin_last_name}
              </div>
            ) : (
              <div className='go-textblacknormal-16 font-medium'>{data?.first_name + ' ' + data?.last_name}</div>
            )}
          </div>
          <div className='mb-4 flex-row space-y-1.5'>
            <div className='go-label-16'>Email</div>
            <div className='go-textblacknormal-16 font-medium'>{data?.email}</div>
          </div>
          <div className='mb-4 flex-row space-y-1.5'>
            {data?.account_type === 'ORGANIZATION' ? (
              <PhoneNumbersList phoneNumbers={data?.org_admin_phone_number && data?.org_admin_phone_number} />
            ) : (
              <PhoneNumbersList phoneNumbers={data?.phone_numbers && data?.phone_numbers} />
            )}
          </div>
          {data?.account_type != 'ORGANIZATION_MEMBER' &&
            (profileData?.status == 'ACTIVE' ? (
              <div className='flex'>
                <Link to='#' className='go-link-16 cursor-pointer' onClick={(e) => deactivateModalFn(e, true)}>
                  Deactivate Account
                </Link>
              </div>
            ) : (
              <div className='flex items-center justify-between'>
                <Link
                  to='#'
                  className='go-textwhite-14 cursor-pointer rounded-full bg-[#548ff7] py-2 px-3 text-center '
                  onClick={(e) => showReactivateModalFn(e, true)}
                >
                  Activate Your Account
                </Link>
                <span className='go-label-13'>Your account is not active</span>
              </div>
            ))}{' '}
        </div>
      </div>
      <ReactivateModal activateModal={activateModal} setActivateModal={setActivateModal} />
      <ModalWithHeadingSmall
        modalOpen={deactivateModal}
        setModalOpen={setDeactivateModal}
        title='Are you sure you want to deactivate your account?'
        classes='max-w-3xl py-10'
      >
        {loading ? (
          <Loader classes='h-60' text={loaderMsg} />
        ) : (
          <div>
            <p className='go-heading-18 mx-10 my-5 text-center'>{warningMsgTextRemoveFn2(accountData?.message)}</p>
            <ul className='m-5 list-disc flex-row space-y-2 sm:mx-40'>
              {accountData?.orders?.map((item, index) => {
                return (
                  <li key={'ordercancel' + index} className='go-textblack-15 opacity-80'>
                    Order {item?.id},{' '}
                    {dateFn('toLocal', item?.pickup_date, item?.pickup_start_time, item?.pickup_end_time)}
                    {item?.warning_message && (
                      <p className='!mt-0 text-sm text-red-500'>{warningMsgTextRemoveFn(item?.warning_message)}</p>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className='mx-8'>
          <div className='flex-col justify-center py-5 text-center '>
            <ClientButton
              variant='secondary'
              text='No'
              classes=''
              onClick={() => {
                setDeactivateModal(false);
              }}
            />
            <ClientButton
              variant='cancel'
              text='Yes'
              classes=''
              onClick={() => {
                onYesDeactivateFn();
              }}
            />
          </div>
        </div>
      </ModalWithHeadingSmall>
    </div>
  );
}
