import React, { useEffect, useState } from 'react';
import Profile from './Profile';
import Payments from './Payments';
import Orders from './Orders';
import Notifications from './Notifications';
import EditIcon from '../../components/Icons/EditIcon';
import ServiceAddresses from './ServiceAddresses';
import ClientButton from '../../components/ClientButton';
import DropdownSingleAddress from '../../components/DropdownSingleAddress';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

export default function MyAccount() {
  const { allServiceAddresses } = useSelector((state) => state.serviceAddresses);
  const { newSubscriptionId } = useSelector((state) => state.createNewOrder);
  const { profileData } = useSelector((state) => state.profile);
  const [allLocations, setAllLocations] = useState([]);
  const [tabs, setTabs] = useState([
    { name: 'Profile', active: true },
    { name: 'Payments', active: false },
    { name: 'Orders', active: false },
    // { name: 'Subscriptions', active: false },
    // { name: 'Preferences', active: false },
    { name: 'Notifications', active: false },
    { name: 'ServiceAddress', active: false },
  ]);
  const setActiveTab = (name) => {
    const updatedTabs = tabs.map((tab) => {
      if (tab.name === name) {
        tab.active = true;
      } else {
        tab.active = false;
      }
      return tab;
    });
    setNewAddress(false);
    setTabs(updatedTabs);
  };
  const [newAddress, setNewAddress] = useState(false);
  const newAddressFn = (val) => {
    setActiveTab('ServiceAddress');
    setNewAddress(true);
  };
  useEffect(() => {}, [newAddress]);
  useEffect(() => {
    if (allServiceAddresses) {
      const locations = allServiceAddresses.map((address) => {
        return {
          id: address.id,
          value: `${address?.name}, ${address?.address_line_1}`,
        };
      });
      setAllLocations(locations);
    }
  }, [allServiceAddresses]);

  useEffect(() => {
    if (!isEmpty(profileData?.past_due_invoices)) {
      setActiveTab('Payments');
    }
  }, [profileData?.past_due_invoices]);
  useEffect(() => {
    if (newSubscriptionId) {
      setActiveTab('Orders');
    }
  }, [newSubscriptionId]);

  const renderTab = (tabname) => {
    switch (tabname) {
      case 'Profile':
        return <Profile />;
      // return <Profile setActiveTab={setActiveTab} />;
      case 'Payments':
        return <Payments />;
      case 'Orders':
        //   return <Payments setActiveTab={setActiveTab} allLocations={allLocations} />;
        // case 'Subscriptions':
        return <Orders />;
      case 'Notifications':
        return <Notifications />;
      case 'ServiceAddress':
        return (
          <ServiceAddresses
            newaddress={newAddress}
            cancelNewAddress={(val) => setNewAddress(val)}
            setTabs={setActiveTab}
          />
        );

      default:
        return <Profile />;
    }
  };
  return (
    <>
      <div className='mb-2 flex flex-col  justify-between sm:flex-row'>
        <ul className=' inline-flex h-10 rounded-full border border-blue-300 bg-white sm:h-12'>
          {tabs.map((tab, index) => {
            if (tab.name != 'ServiceAddress') {
              return (
                <li key={index}>
                  <button
                    onClick={() => setActiveTab(tab.name)}
                    className={`${
                      tab.active ? 'go-link-18 bg-[#EDF3FE]' : 'go-labelmedium-18'
                    }  mt-0.5 flex items-center rounded-full px-3 py-1.5 sm:px-8 sm:py-2`}
                  >
                    {tab.name}
                  </button>
                </li>
              );
            }
          })}
        </ul>
        {tabs.find((tab) => tab.active).name === 'ServiceAddress'
          ? !newAddress &&
            profileData?.is_allowed_to_add_address && (
              <ClientButton
                variant='primary'
                text='Add a Service Address'
                classes=''
                size='small'
                onClick={() => {
                  setNewAddress(true);
                }}
              />
            )
          : (tabs.find((tab) => tab.active).name === 'Orders' ||
              tabs.find((tab) => tab.active).name === 'Payments') && (
              <div className='w-full pt-2 sm:w-auto'>
                <span className='go-label-16'>Location</span>
                <div className='flex'>
                  <DropdownSingleAddress dropdownValues={allLocations} newAddress={(e) => newAddressFn(e)} />
                  <button className='go-btn-primary ml-2 mt-1'>
                    <EditIcon onClick={() => setActiveTab('ServiceAddress')} />
                  </button>
                </div>
              </div>
            )}
      </div>
      <hr className='go-hr-dashed my-5'></hr>
      {/* <hr className='go-hr-dashed my-5'></hr> */}
      {renderTab(tabs.find((tab) => tab.active).name)}
    </>
  );
}
