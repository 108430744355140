import React, { useCallback, useEffect, useState } from 'react';
import AccountInfo from './AccountInfo';
import MyUser from './MyUser';
import Members from './Members';
import EditMembers from './EditMember';
import AddMembers from './AddMembers';
import EditMyUser from './EditMyUser';
import { useDispatch, useSelector } from 'react-redux';
import EditAccountInfo from './EditAccountInfo';
import Loader from '../../../components/Loader';
import { getProfileDataApi } from '../../../redux/Reducers/ProfileReducer';
import ServiceAddressesCard from './ServiceAddressesCard';
import ServiceAddressesTable from './ServiceAddressesTable';
import ServiceAddressModal from '../../CreateNewOrder/PickupAddress/ServiceAddressModal';

export default function Profile({ setActiveTab }) {
  const dispatch = useDispatch();
  const { profileData } = useSelector((state) => state.profile);
  const { profileLoading } = useSelector((state) => state.profile);
  const { allServiceAddresses } = useSelector((state) => state.serviceAddresses);
  const [addMember, setAddMember] = useState(false);
  const [editMembers, setEditMembers] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [editAccountInfo, setEditAccountInfo] = useState(false);
  const [newAddressModalOpen, setNewAddressModalOpen] = useState(false);
  useEffect(() => {
    addMember && dispatch(getProfileDataApi(false));
  }, [addMember]);

  return (
    <>
      {profileLoading ? (
        <Loader text='loading profile...' classes='h-80' />
      ) : (
        <div>
          {editMembers ? (
            addMember ? (
              <div className='grid-col-1 grid gap-6 sm:grid-cols-3'>
                <AddMembers setAdd={(val) => setAddMember(val)} data={profileData || {}} />
              </div>
            ) : (
              <EditMembers
                data={profileData || {}}
                add={addMember}
                setAdd={(val) => setAddMember(val)}
                setEdit={(val) => setEditMembers(val)}
              />
            )
          ) : (
            <div className='grid-col-1 grid gap-6 sm:grid-cols-3'>
              {editUser ? (
                <EditMyUser setEdit={(val) => setEditUser(val)} data={profileData || {}} />
              ) : editAccountInfo ? (
                <EditAccountInfo setEdit={(val) => setEditAccountInfo(val)} data={profileData || {}} />
              ) : (
                <React.Fragment>
                  {profileData?.account_type != 'INDIVIDUAL' && (
                    <AccountInfo setEdit={(val) => setEditAccountInfo(val)} data={profileData || {}} />
                  )}
                  {/* <AccountInfo setEdit={(val) => setEditAccountInfo(val)} data={profileData || {}} /> */}
                  <MyUser edit={editUser} setEdit={(val) => setEditUser(val)} data={profileData || {}} />
                  {profileData?.account_type == 'ORGANIZATION' && (
                    <Members data={profileData || {}} edit={editMembers} setEdit={(val) => setEditMembers(val)} />
                  )}
                  {/* {allServiceAddresses?.length <= 2 && (
                    <ServiceAddressesCard
                      data={profileData || {}}
                      addressess={allServiceAddresses}
                      setNewAddressModalOpen={setNewAddressModalOpen}
                    />
                  )} */}
                </React.Fragment>
              )}
            </div>
          )}
          {/* <ServiceAddressesTable
            addressess={allServiceAddresses}
            setNewAddressModalOpen={setNewAddressModalOpen}
            setActiveTab={setActiveTab}
          />
          <ServiceAddressModal
            newAddressModalOpen={newAddressModalOpen}
            setNewAddressModalOpen={setNewAddressModalOpen}
            addressModalType={'new'}
          /> */}
        </div>
      )}
    </>
  );
}
