import React, { useState } from 'react';
import GreenCheckMarkIcon from '../../components/Icons/GreenCheckMarkIcon';
import { Link } from 'react-router-dom';
import { resetCredentials } from '../../redux/Reducers/commonApis';
import { isEmpty } from 'lodash';
import toast from 'react-hot-toast';
import passwordEye from '../../../src/images/passwordeye.png';
import LinkButton from '../../components/LinkButton';
export default function NewPassword({ token, setScreenType }) {
  const [screen, setScreen] = useState('new');
  const password1Ref = React.useRef();
  const password2Ref = React.useRef();
  const [passwordError, setPasswordError] = useState([]);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [isPassword1Valid, setIsPassword1Valid] = useState(false);
  const [isPassword2Valid, setIsPassword2Valid] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const onPassword1ChangeFn = (e) => {
    const regex = /^(?=.*[A-Z].*[A-Z])(?=.*[a-z].*[a-z])(?=.*[\W_].*[\W_])(?=.*\d.*\d).{14,}$/;
    if (e.target.value?.length >= 8) {
      setIsPassword1Valid(true);
    } else {
      setIsPassword1Valid(false);
    }
  };
  const onPassword2ChangeFn = (e) => {
    console.log('e.target.value === password1Ref?.current?.value :>> ', e.target.value, password1Ref?.current?.value);
    if (e.target.value === password1Ref?.current?.value) {
      setIsPassword2Valid(true);
    } else {
      setIsPassword2Valid(false);
    }
  };
  const resetCredentialsFn = () => {
    const payload = {
      new_password: password1Ref?.current?.value,
      confirm_new_password: password2Ref?.current?.value,
      token: token,
    };
    setPasswordLoading(true);
    const response = resetCredentials(payload);
    response?.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setPasswordError([]);
        setPasswordLoading(false);
        setScreen('success');
      } else {
        setPasswordLoading(false);
        setPasswordError(res?.error?.messages || 'Something went wrong');
        toast.error(res?.error?.message || 'Something went wrong');
      }
    });
  };
  return (
    <div className='mx-auto w-[400px] px-4 pt-8'>
      <div className='flex justify-center'>
        {screen != 'new' && <GreenCheckMarkIcon classes='mr-2' />}
        <h1 className='mb-10 text-center text-2xl font-bold text-slate-800'>
          {screen === 'new' ? 'Create New Password' : 'Password Changed'}
        </h1>
      </div>
      {screen == 'success' && (
        <>
          <p className='mb-2 text-center text-base font-normal text-slate-800'>
            Your password has bee changed successfully.
          </p>
          <p className='mb-10 text-center text-base font-normal text-slate-800'>Use your new password to login.</p>
        </>
      )}
      {screen === 'new' && (
        <div>
          <div className='relative mb-4 flex-row space-y-1.5'>
            <label className='go-label-16 mb-1 block' htmlFor='newpassword1'>
              New password
            </label>
            <input
              ref={password1Ref}
              name='newpassword1'
              className={`go-inputtext-16 ` + (isPassword1Valid && isPassword2Valid ? 'border-green-500' : '')}
              type={showPassword1 ? 'text' : 'password'}
              onChange={(e) => onPassword1ChangeFn(e)}
              defaultValue=''
              placeholder='Enter a new password'
            />
            <img
              src={passwordEye}
              alt='eye'
              className='absolute  -right-1 top-7 h-9 w-12'
              onClick={() => setShowPassword1(!showPassword1)}
            />
            {!isPassword1Valid && (
              <p className='go-textnormal-14 text-[#548ff7]'>
                * Passwords must be at least 8 characters, include 1 upper case and 1 lower case letters, and 1 number.
              </p>
            )}
          </div>
          <div className='relative mb-4 flex-row space-y-1.5'>
            <label className='go-label-16 mb-1 block' htmlFor='newpassword2'>
              Confirm new password
            </label>
            <input
              ref={password2Ref}
              name='newpassword2'
              className={`go-inputtext-16 ` + (isPassword1Valid && isPassword2Valid ? 'border-green-500' : '')}
              type={showPassword2 ? 'text' : 'password'}
              onChange={(e) => onPassword2ChangeFn(e)}
              defaultValue=''
              placeholder='Re-enter your new password'
            />
            <img
              src={passwordEye}
              alt='eye'
              className='absolute  -right-1 top-7 h-9 w-12'
              onClick={() => setShowPassword2(!showPassword2)}
            />
            {isPassword1Valid && !isPassword2Valid && (
              <p className='go-textnormal-14 text-red-500'>Password does not match.</p>
            )}
          </div>
          {!isEmpty(passwordError) &&
            passwordError?.map((error, idx) => (
              <p key={idx} className='go-textnormal-14 text-red-500'>
                {error}
              </p>
            ))}
          <div className='mb-2 mt-10 border-slate-200 pb-2'>
            <div className='flex items-center justify-between space-x-4'>
              <LinkButton
                onClick={() => {
                  setScreenType('login');
                }}
                variant='secondary'
                text='Cancel'
              />
              <LinkButton
                onClick={() => {
                  resetCredentialsFn();
                }}
                variant='primary'
                text='Save'
                disabled={!isPassword1Valid || !isPassword2Valid}
                loading={passwordLoading}
              />
            </div>
          </div>
        </div>
      )}
      {screen == 'success' && (
        <div className='flex items-center justify-between space-x-4'>
          <LinkButton
            onClick={() => {
              setScreenType('login');
            }}
            variant='primary'
            text='Log In'
          />
        </div>
      )}
    </div>
  );
}
