import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { createOTP, forgotPasswordApi, validateOTP } from '../../redux/Reducers/commonApis';
import toast from 'react-hot-toast';
import { set } from 'lodash';
import LinkButton from '../../components/LinkButton';

export default function ResetPassword({ emailObject, setScreenType, setToken }) {
  const [stepType, setStepType] = useState('resethome');
  const [mainText, setMainText] = useState('Reset Password');
  const [secondaryText, setSecondaryText] = useState('');
  const [resetType, setResetType] = useState(emailObject?.phone_number_type == 'MOBILE' ? 'phone' : 'email');
  const [resendCount, setResendCount] = useState(30);
  const [otp, setOTP] = useState('');
  const [createOTPLoading, setCreateOTPLoading] = useState(false);
  const [validateOTPLoading, setValidateOTPLoading] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState({
    message: '',
    isValid: true,
  });
  const [emailResetLoading, setEmailResetLoading] = useState(false);
  const [resetMsg, setResetMsg] = useState('');
  const createOTPFn = () => {
    setInvalidOtp({
      message: '',
      isValid: true,
    });
    setOTP('');
    setCreateOTPLoading(true);
    setResendCount(30);
    const payload = {
      email: emailObject?.email,
    };
    const response = createOTP(payload);
    response?.then((res) => {
      setCreateOTPLoading(false);
      if (res?.status === 200 || res?.status === 201) {
        toast.success(res?.data?.message);
        setStepType('otpsent');
        setMainText('Enter One-Time Code');
        setSecondaryText(res?.data?.message);
        startCountDownFn();
      } else {
        toast.error(res?.message);
        setStepType('otperror');
        startCountDownFn();
      }
    });
  };
  const validateOTPFn = () => {
    setValidateOTPLoading(true);
    const payload = {
      email: emailObject?.email,
      otp: otp,
    };
    const response = validateOTP(payload);
    response?.then((res) => {
      setValidateOTPLoading(false);
      if (res?.status === 200 || res?.status === 201) {
        toast.success('OTP verified successfully');
        setScreenType('newpassword');
        setToken(res?.data?.token);
      } else {
        setInvalidOtp({
          message: res?.message,
          isValid: false,
        });
        // toast.error(res?.message);
      }
    });
  };

  const emailResetFn = async () => {
    setEmailResetLoading(true);
    const payload = {
      email: emailObject?.email,
    };
    const result = forgotPasswordApi(payload);
    result?.then((res) => {
      setEmailResetLoading(false);
      if (res?.status === 200 || res?.status === 201) {
        setStepType('emailreset');
        setResetMsg(res?.data);
        toast.success('Email sent successfully');
      } else {
        toast.error(res?.message);
      }
    });
  };

  const startCountDownFn = () => {
    const interval = setInterval(() => {
      setResendCount((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);
  };

  return (
    <div className='mb-2 mt-12 border-slate-200 pb-2'>
      {stepType != 'emailreset' && (
        <h1 className={'text-center text-2xl font-bold text-slate-800 ' + (stepType == 'otpsent' ? 'mb-2' : 'mb-10')}>
          {mainText}
        </h1>
      )}

      <div className='mx-auto w-[400px] px-4 pt-8'>
        {stepType == 'otpsent' && (
          <p className='mb-10 text-center text-sm font-normal text-slate-800'>{secondaryText}</p>
        )}
        {stepType == 'resethome' && (
          <div>
            {emailObject?.phone_number_type == 'MOBILE' && (
              <div className='mb-2 flex items-start'>
                <input
                  id='phonelabel'
                  type='checkbox'
                  className='form-checkbox mt-0.5'
                  onChange={(e) => setResetType('phone')}
                  checked={resetType == 'phone'}
                />
                <label htmlFor='phonelabel' className='go-textblack-15 ml-4 block font-normal'>
                  Receive reset code via text
                </label>
              </div>
            )}

            <div className='mb-8 flex items-start'>
              <input
                id='emaillabel'
                type='checkbox'
                className='form-checkbox mt-0.5'
                onChange={(e) => setResetType('email')}
                checked={resetType == 'email'}
              />
              <label htmlFor='emaillabel' className='go-textblack-15 ml-4 block font-normal'>
                Receive reset link via email
              </label>
            </div>
          </div>
        )}
        {stepType == 'otperror' && (
          <div>
            <div className='mb-2 flex items-start'>
              <p className='go-textnormal-14 mb-4 text-red-500'>
                We couldn’t send the reset code right now. Please try again or click ‘Send Email’ to receive a link to
                reset your password.
              </p>
            </div>
          </div>
        )}
        {stepType == 'otpsent' && (
          <div>
            <label
              className={invalidOtp?.isValid ? 'go-label-15' : ' go-textnormal-14 mb-4 text-red-500'}
              htmlFor='email'
            >
              {invalidOtp?.isValid ? 'Code' : '* Code'}
            </label>
            <div className='mb-2 flex items-start'>
              <input
                type='text'
                className='go-inputtext-15 w-full'
                onChange={(e) => {
                  setOTP(e.target.value);
                }}
                value={otp}
                placeholder='Enter the one-time code'
              />
            </div>
            {!invalidOtp?.isValid && <p className='go-textnormal-14 mb-4 text-red-500'>{invalidOtp?.message}</p>}
            <div className='mb-2 flex items-end justify-end'>
              {resendCount == 0 ? (
                <Link
                  className='text-sm font-semibold text-[#548ff7]'
                  to='#'
                  onClick={() => {
                    createOTPFn();
                  }}
                >
                  Resend Code
                </Link>
              ) : (
                <p className='go-textnormal-14 mb-1 text-right text-blue-500'>
                  request new code in {resendCount} seconds
                </p>
              )}
            </div>
          </div>
        )}
        {(stepType == 'resethome' || stepType == 'otperror') && (
          <div className='relative'>
            <label className=' go-label-15' htmlFor='email2'>
              Email
            </label>
            <div className='flex justify-between'>
              <span>{emailObject?.email}</span>
              <Link
                className='text-sm font-semibold text-[#548ff7]'
                to='#'
                onClick={() => {
                  setScreenType('login');
                }}
              >
                Change
              </Link>
            </div>
          </div>
        )}
      </div>

      {stepType == 'emailreset' && (
        <div className='mx-auto w-full px-4 py-8 text-center sm:w-2/4'>
          <h1 className='mb-6 text-xl font-bold text-slate-800 sm:text-3xl'>{resetMsg}</h1>
          <div className='mb-2 border-slate-200 pb-2 font-semibold'>
            <p>
              Didn't receive an email?{' '}
              <Link
                className='text-[#548ff7]'
                to='#'
                onClick={(e) => {
                  emailResetFn();
                }}
              >
                Send Again.
              </Link>
            </p>
          </div>
        </div>
      )}
      <div className='mx-auto w-[400px] px-4'>
        {stepType == 'resethome' && (
          <div className='mb-2 mt-10 border-slate-200 pb-2'>
            <div className='flex items-center justify-between space-x-4'>
              <LinkButton
                onClick={() => {
                  setScreenType('login');
                }}
                variant='secondary'
                text='Cancel'
              />
              <LinkButton
                onClick={() => {
                  if (resetType == 'phone') {
                    createOTPFn();
                  } else {
                    emailResetFn();
                  }
                }}
                variant='primary'
                text='Continue'
                disabled={createOTPLoading || emailResetLoading}
                loading={createOTPLoading || emailResetLoading}
              />
            </div>
          </div>
        )}
        {stepType == 'otperror' && (
          <div className='mb-2 mt-10 border-slate-200 pb-2'>
            {resendCount != 0 && (
              <p className='go-textnormal-14 mb-1 text-right text-red-500'>request new code in {resendCount} seconds</p>
            )}
            <div className='flex items-center justify-between space-x-4'>
              <LinkButton onClick={() => {}} variant='secondary' text='Send Email' />
              <LinkButton
                onClick={() => {
                  createOTPFn();
                }}
                variant='primary'
                text='Try Again'
                disabled={resendCount > 0}
              />
            </div>
          </div>
        )}
        {stepType == 'otpsent' && (
          <div className='mb-2 mt-10 border-slate-200 pb-2'>
            <div className='flex items-center justify-between space-x-4'>
              <LinkButton
                onClick={() => {
                  setStepType('resethome');
                }}
                variant='secondary'
                text='Back'
              />
              <LinkButton
                onClick={() => {
                  validateOTPFn();
                }}
                variant='primary'
                text='Continue'
                disabled={otp?.length != 6 || validateOTPLoading}
                loading={validateOTPLoading}
              />
            </div>
          </div>
        )}
        {stepType == 'emailreset' && (
          <div className='flex items-center justify-between space-x-4'>
            <LinkButton
              onClick={() => {
                setScreenType('login');
              }}
              variant='primary'
              text='Log In'
            />
          </div>
        )}
      </div>
    </div>
  );
}
